<template>
  <div>
    <h3>Publisher Report</h3>
    <v-row v-for="rep in track_report" :key="rep.language" style="padding-bottom: 400px">
      <v-col>
        <v-row>
          <v-col>
            <table border="1" style="width: 100%">
              <thead bgcolor="orange" style="text-align: center; font-weight: bold">
                <th>Publisher</th>
                <th>Language</th>
                <th>Date From</th>
                <th>Date To</th>
              </thead>
              <tbody style="text-align: center;">
              <tr>
                <td>{{ rep.publisher }}</td>
                <td>{{ rep.language }}</td>
                <td>{{ search.dateFrom }}</td>
                <td>{{ search.dateTo }}</td>
              </tr>
              <tr bgcolor="#FFA07A" style="font-weight: bold;  text-align: center">
                <td>Total Ad Revenue (RM)</td>
                <td>Total Published On Newswav</td>
                <td>Total Article Views</td>
                <td>Total Reaction Counts</td>
              </tr>
              <tr>
                <td>{{ rep.total_ad_revenue }}</td>
                <td>{{ rep.total_published_on_newswav }}</td>
                <td>{{ rep.total_article_views }}</td>
                <td>{{ rep.total_reaction_count }}</td>
              </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4>Top 10 Article Views</h4>
            <table border="1" style="width: 100%">
              <thead style="background-color: #00FF7F; color: black; text-align: center">
              <th>Title</th>
              <th>View Count</th>
              <th>Reaction Count</th>
              </thead>
              <tbody>
              <tr v-for="item in rep.top_10_article" :key="item.unique_id">
                <td>{{ item.title }}</td>
                <td>{{ item.view_count }}</td>
                <td>{{ item.reaction_count }}</td>
              </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4>Top 10 Reacted Articles</h4>
            <table border="1" style="width: 100%">
              <thead style="background-color: #00FF7F; color: black; text-align: center">
              <th>Title</th>
              <th>View Count</th>
              <th>Reaction Count</th>
              </thead>
              <tbody>
              <tr v-for="item in rep.top_10_reacted_article" :key="item.unique_id">
                <td>{{ item.title }}</td>
                <td>{{ item.view_count }}</td>
                <td>{{ item.reaction_count }}</td>
              </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
        <br>
        <br>
<!--        <br>-->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "GenerateReport",
  data() {
    return {
      track_report: [],
      search: {
        publisher_id: '',
        dateFrom: '',
        dateTo: ''
      },
    }
  },
  created() {
    this.search.publisher_id = this.$route.params.publisher_id
    this.search.dateFrom = this.$route.params.dateFrom
    this.search.dateTo = this.$route.params.dateTo

    this.getReport()
  },
  methods: {
    getReport() {
      const config = {
        headers: {
          hash: process.env.VUE_APP_ADMIN_TOKEN
        }
      }
      axios.post(process.env.VUE_APP_TRACK_PUBLISHER, this.search, config)
          .then(res => {
            this.track_report = res.data
          })
          .catch(err => {
            alert("Something went wrong, check logs")
            console.log(err)
          })
    }
  }
}
</script>

<style scoped>
td {
  padding: 5px
}
</style>